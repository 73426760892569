import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  FormGroup,
} from "@mui/material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import _ from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { enqueueSnackbar } from "notistack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const { REACT_APP_ACCOUNTS_API_URL, REACT_APP_BASE_API_URL } = process.env;

export default function AddTransactionType() {
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState({});
  const [accStateName, setAccStateName] = React.useState([]);
  const [accStates, setAccStates] = React.useState([]);
  const axios = useAxiosPrivate();
  const [pinStatusName, setPinStatusName] = React.useState([]);
  const [pinStatuses, setPinStatuses] = React.useState([]);

  const navigate = useNavigate();

  const instructions = ["DEBIT", "CREDIT", "TRANSFER"];
  const chargeTypes = ["ABSOLUTE", "PERCENTAGE"];

  const endpoint = "transaction-type";
  const accStateUrl = "account-state/all";
  const pinStatusUrl = "pinstatus/all";

  const handleMulValChange = (event) => {
    const {
      target: { name, value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    name === "allowed_account_states" && setAccStateName(duplicateRemoved);
    name === "allowed_pin_statuses" && setPinStatusName(duplicateRemoved);
  };

  const add = (e) => {
    const data = new FormData(e.currentTarget);
    e.preventDefault();

    const biller_service = data.get("biller_service");
    const service_url = data.get("service_url");
    const charge = data.get("charge");
    const charge_type = data.get("charge_type");
    const type = data.get("type");
    const short_form = data.get("short_form");
    const instruction = data.get("instruction");

    const reversible = data.get("reversible");

    const allowed_pin_statuses = data.get("allowed_pin_statuses");
    const allowed_account_states = data.get("allowed_account_states");

    setType({
      biller_service,
      service_url,
      charge,
      charge_type,
      type,
      short_form,
      instruction,
      reversible,
      allowed_pin_statuses,
      allowed_account_states,
    });
    axios
      .post(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}`, {
        biller_service,
        service_url,
        charge,
        charge_type,
        type,
        short_form,
        instruction,
        reversible,
        allowed_pin_statuses,
        allowed_account_states,
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Transaction type added successfully.", {
            variant: "success",
          });
          navigate("/transaction-types");
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  React.useEffect(() => {
    setLoading(true);
    let accStateNames = [];
    let pinStatusNames = [];
    axios.get(`${REACT_APP_ACCOUNTS_API_URL}/${accStateUrl}`).then((res) => {
      const { result } = res.data;
      result.map((state) => {
        accStateNames.push(state.state);
      });
    });
    axios
      .get(`${REACT_APP_BASE_API_URL}/${pinStatusUrl}`)
      .then((res) => {
        const { result } = res.data;
        result.map((status) => {
          pinStatusNames.push(status.status);
        });
      })
      .then((_) => {
        setPinStatuses(pinStatusNames);
        setAccStates(accStateNames);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <Typography
        component={"h1"}
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        Add New Transaction Type
      </Typography>
      {loading ? (
        "LOADING..."
      ) : (
        <Box mt={2} component="form" onSubmit={add}>
          <FormGroup>
            <Grid item direction={"row"}>
              <TextField
                required
                fullWidth
                label="Type"
                id="type"
                name="type"
                sx={{ mb: 1 }}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="instruction">Instruction</InputLabel>
                <Select
                  required
                  labelId="instruction"
                  id="instruction"
                  label="Instruction"
                  name="instruction"
                  sx={{ mb: 1 }}
                  // onChange={handleChange}
                >
                  {instructions.map((instruction) => (
                    <MenuItem value={instruction}>{instruction}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Service URL"
                id="service_url"
                name="service_url"
                sx={{ mb: 1 }}
              />
            </Grid>

            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="charge_type">Charge Type</InputLabel>
                <Select
                  required
                  labelId="charge_type"
                  id="charge_type"
                  label="Charge Type"
                  name="charge_type"
                  sx={{ mb: 1 }}
                >
                  {chargeTypes.map((type) => (
                    <MenuItem value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <TextField
                required
                fullWidth
                label="Charge"
                id="charge"
                name="charge"
                sx={{ mb: 1 }}
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                label="Short Form"
                id="short_form"
                name="short_form"
                sx={{ mb: 1 }}
              />
            </Grid>
            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="allowed_pin_statuses">
                  Allowed PIN Statuses
                </InputLabel>
                <Select
                  labelId="allowed_pin_statuses"
                  id="allowed_pin_statuses"
                  name="allowed_pin_statuses"
                  multiple
                  value={pinStatusName}
                  sx={{ mb: 1 }}
                  onChange={handleMulValChange}
                  input={<OutlinedInput label="allowed_pin_statuses" />}
                  renderValue={(selected) =>
                    _.toString(selected).length > 30
                      ? _.join(selected, ", ").slice(0, 33).concat("...")
                      : selected.map((x) => x).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {pinStatuses.map((status, index) => (
                    <MenuItem key={index} value={status}>
                      <Checkbox
                        checked={
                          pinStatusName.findIndex((item) => item === status) >=
                          0
                        }
                      />
                      <ListItemText primary={status} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="role">Allowed Account States</InputLabel>
                <Select
                  labelId="allowed_account_states"
                  id="allowed_account_states"
                  name="allowed_account_states"
                  multiple
                  sx={{ mb: 1 }}
                  value={accStateName}
                  onChange={handleMulValChange}
                  input={<OutlinedInput label="allowed_account_states" />}
                  renderValue={(selected) =>
                    _.toString(selected).length > 30
                      ? _.join(selected, ", ").slice(0, 33).concat("...")
                      : selected.map((x) => x).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {accStates.map((state, index) => (
                    <MenuItem key={index} value={state}>
                      <Checkbox
                        checked={
                          accStateName.findIndex((item) => item === state) >= 0
                        }
                      />
                      <ListItemText primary={state} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <FormLabel id="reversible" mr={1}>
                  Reversible?
                </FormLabel>
                <RadioGroup
                  required
                  aria-labelledby="reversible"
                  defaultValue={false}
                  name="reversible"
                  sx={{ mb: 1 }}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl>
                <FormLabel id="biller_service" mr={1}>
                  <Typography>Biller Service?</Typography>
                </FormLabel>
                <RadioGroup
                  required
                  aria-labelledby="biller_service"
                  defaultValue={true}
                  name="biller_service"
                  sx={{ mb: 1 }}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                startIcon={<CheckIcon />}
                type="submit"
                color="primary"
                sx={{ m: 1 }}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                color="warning"
                sx={{ m: 1 }}
                onClick={() => navigate(`${"/transaction-types"}`)}
              >
                Cancel
              </Button>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </React.Fragment>
  );
}
