import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddClient() {
  const [client, setClient] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const axios = useAxiosPrivate();
  const endpoint = "client";

  const add = (e) => {
    setOpen(false);
    const data = new FormData(e.currentTarget);
    e.preventDefault();
    const clientName = data.get("clientName");
    const physicalAddress = data.get("physicalAddress");
    const primaryEmail = data.get("primaryEmail");
    const primaryTel = data.get("primaryTel");

    setClient({ clientName, physicalAddress, primaryEmail, primaryTel });
    axios
      .post(`${process.env.REACT_APP_BASE_API_URL}/${endpoint}`, {
        client_name: clientName,
        physical_address: physicalAddress,
        primary_email: primaryEmail,
        primary_tel: primaryTel,
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Client added successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  return (
    <Grid
      item
      xs={12}
      lg={12}
      md={12}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      pb={1}
    >
      <Paper>
        <div>
          <Button onClick={handleOpen}>Add New Client</Button>
          <Modal
            aria-labelledby="addClient"
            aria-describedby="add new client"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="addClient"
                  variant="h6"
                  component="h2"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Enter Client Details
                </Typography>

                <Box
                  mt={2}
                  sx={{
                    width: 500,
                    maxWidth: "100%",
                    m: 1,
                  }}
                  component="form"
                  onSubmit={add}
                >
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <TextField
                        required
                        fullWidth
                        label="Client Name"
                        id="clientName"
                        name="clientName"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        fullWidth
                        label="Email"
                        id="primaryEmail"
                        name="primaryEmail"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        fullWidth
                        label="Physical Address"
                        id="physicalAddress"
                        name="physicalAddress"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        fullWidth
                        label="Primary Phone"
                        id="primaryTel"
                        name="primaryTel"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        sx={{ display: "flex", justifyContent: "center" }}
                        variant="outlined"
                        startIcon={<AddIcon />}
                        color="primary"
                      >
                        Add Client
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </div>
      </Paper>
    </Grid>
  );
}
