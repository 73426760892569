import BlockIcon from "@mui/icons-material/Block";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
// import axios from "axios";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { blue, green, red } from "@mui/material/colors";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import { AppContext } from "../helpers/contexts/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
export default function Accounts() {
  const { REACT_APP_ACCOUNTS_API_URL } = process.env;
  const endpoint = "account";
  const accountEndpoint = "accounts";
  const freezeEndpoint = "freeze";
  const suspendEndpoint = "suspend";
  const approveEndpoint = "approve";
  const resetBalanceEndpoint = "reset-digest";

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();

  const [accounts, setAccounts] = React.useState([]);
  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    CustomToolbar,
    auth,
    toggleBoolField,
  } = React.useContext(AppContext);
  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [payload, setPayload] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const effectRun = React.useRef(false);

  const colors = {
    freeze: red[200],
    suspend: red[500],
    activate: blue[900],
    approve: green[300],
  };

  const resetBalance = (id) => {
    axiosPrivate
      .post(
        `${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${resetBalanceEndpoint}/${id}`
      )
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Balance Digest reset successfully", {
            variant: "success",
          });
        }
      });
  };

  const activateData = {
    state: "ACTIVE",
  };

  const activateAccount = (id) => {
    axiosPrivate
      .patch(
        `${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}/change-state`,
        activateData
      )
      .then((res) => {
        if (res.status === 200) {
          setAccounts(accounts.filter((ac) => ac.id !== id));
          enqueueSnackbar(`Account activated successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const approveAccount = (id) => {
    axiosPrivate
      .patch(
        `${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}/${approveEndpoint}`
      )
      .then((res) => {
        if (res.status === 200) {
          return enqueueSnackbar("Account approved successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const freezeAccount = (id) => {
    axiosPrivate
      .patch(
        `${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}/${freezeEndpoint}`,

        {
          state: 3,
          maker_narration: "Freezing account due suspected fraud",
        }
      )
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar("Account frozen successfully.", {
            variant: "success",
          });
        }

        enqueueSnackbar("Error freezing account. Try again later.", {
          variant: "error",
        });
      });
  };

  const suspendAccount = (id) => {
    axiosPrivate
      .patch(
        `${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}/${suspendEndpoint}`,
        {
          state: 3,
          maker_narration: "Suspending account due to suspected fraud",
        }
      )
      .then((res) => {
        if (res.status === 200) {
          return enqueueSnackbar(`Account suspended successfully.`, "success");
        }
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleView = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    return navigate(`/${accountEndpoint}/${thisRow.id}`);
  };

  const handleApprove = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setDialogHeader("Approve Account");
    setactionPresent(true);
    setAction("approve");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const handleActivate = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to activate account ${thisRow.account_number}?`
    );
    setDialogHeader("Activate Account");
    setactionPresent(true);
    setAction("activate");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const handleFreeze = (e, params) => {
    e.stopPropagation();
    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to freeze account ${thisRow.account_number}?`
    );
    setDialogHeader("Freeze Account");
    setactionPresent(true);
    setAction("freeze");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const handleSuspend = (e, params) => {
    e.stopPropagation();
    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to suspend account ${thisRow.account_number}?`
    );
    setDialogHeader("Suspend Account");
    setactionPresent(true);
    setAction("suspend");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const handleReset = (e, params) => {
    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to reset balance for account ${thisRow.account_number}?`
    );
    setDialogHeader("Reset Account Balance");
    setactionPresent(true);
    setAction("reset");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const actions = [
    {
      name: "view",
      onclick: (e, params) => handleView(e, params),
      icon: <ChromeReaderModeIcon />,
      color: "primary",
      label: "View",
      role: "VIEW_ACCOUNT",
      toggle_state: "",
    },
    {
      name: "approve",
      onclick: (e, params) => handleApprove(e, params),
      icon: <CheckCircleOutlineIcon />,
      color: colors.approve,
      label: "Approve",
      role: "APPROVE_ACCOUNT",
      toggle_state: "",
    },
    {
      name: "freeze",
      onclick: (e, params) => handleFreeze(e, params),
      icon: <LockPersonIcon />,
      color: colors.freeze,
      label: "Freeze",
      role: "FREEZE_ACCOUNT",
      toggle_state: "FROZEN",
    },
    {
      name: "suspend",
      onclick: (e, params) => handleSuspend(e, params),
      icon: <BlockIcon />,
      color: colors.suspend,
      label: "Suspend",
      role: "SUSPEND_ACCOUNT",
      toggle_state: "SUSPENDED",
    },
    {
      name: "reset",
      onclick: (e, params) => handleReset(e, params),
      icon: <RestartAltIcon />,
      color: "primary",
      label: "Reset Bal.",
      role: "RESET_ACCOUNT_BALANCE",
      toggle_state: "",
    },
    {
      name: "activate",
      onclick: (e, params) => handleActivate(e, params),
      icon: <ToggleOffIcon />,
      color: colors.activate,
      label: "Activate",
      role: "ACTIVATE_ACCOUNT",
      toggle_state: "",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "account_number", headerName: "Account #", width: 150 },
    { field: "running_balance", headerName: "Balance", width: 100 },

    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Button
              disabled={action.toggle_state === params.row.state ? true : false}
              variant="outlined"
              startIcon={action.icon}
              sx={{ m: 0.5, color: action.color }}
              onClick={(e) => action.onclick(e, params)}
              disableRipple={true}
              aria-label={action.label}
            >
              {action.label}
            </Button>
          ) : (
            ""
          )
        );
      },
      width: 800,
      headerAlign: "center",
    },
  ];

  React.useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    setLoading(true);

    const getAccounts = async () => {
      let accs = [];
      // try {
      const response = await axiosPrivate.get(
        `${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/all`,
        {
          signal: controller.signal,
        }
      );

      const { result } = response.data;
      result.map((row) => {
        row.account_owner_username = row.account_owner.username;
        row.running_balance = row.running_balance.toFixed(2);
        accs.push(row);
      });
      console.table(result);
      isMounted && setAccounts(accs);
    };

    getAccounts();
    setLoading(false);

    return () => {
      isMounted = false;
      controller.abort();
      effectRun.current = true; // update the value of effectRun to true
    };
  }, []);

  return (
    <React.Fragment>
      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={() => handleOpenConfirmDialog()}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={
            action === "activate"
              ? activateAccount
              : action === "freeze"
              ? freezeAccount
              : action === "suspend"
              ? suspendAccount
              : action === "approve"
              ? approveAccount
              : resetBalance
          }
          payload={payload}
        />
      )}
      <Button
        sx={{ mb: 1 }}
        size="small"
        onClick={() => navigate("/accounts/add")}
      >
        Add New Account
      </Button>
      <div style={{ width: "100%" }}>
        {
          <DataGrid
            autoHeight
            rows={accounts}
            columns={columns}
            rowsPerPageOptions={[20, 50, 100]}
            getRowHeight={() => "auto"}
            disableColumnMenu
            loading={loading}
            components={{ Toolbar: CustomToolbar }}
          />
        }
      </div>
    </React.Fragment>
  );
}
