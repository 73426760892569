import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function EditClient({
  openEditDialog,
  handleCloseEditDialog,
  dialogHeader,
  actionPresent,
  handler,
  row,
  param,
}) {
  const [fields, setFields] = React.useState({});
  const [payload, setPayload] = React.useState({});
  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    Object.entries(row)
      .slice(1, -2)
      .map((col) => setFields({ ...fields, [col[0]]: col[1] }));
  }, []);

  return (
    <div>
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{dialogHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {Object.entries(row)
              .slice(0, -2)
              .map((key, index) => (
                <TextField
                  disabled={index === 0}
                  autoFocus
                  margin="dense"
                  id={key[0]}
                  name={key[0]}
                  label={key[0]
                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                    .toLowerCase()}
                  type={
                    key[0].toLowerCase().includes("email") ? "email" : "text"
                  }
                  fullWidth
                  variant="outlined"
                  defaultValue={key[1]}
                  key={key[0]}
                  onChange={handleChange}
                />
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              handleCloseEditDialog(false, handler, param, payload)
            }
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleCloseEditDialog(actionPresent, handler, param, payload)
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
