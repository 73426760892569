import { useEffect, useContext } from "react";
import { axiosPrivate } from "../api/axios";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  // const navigate = useNavigate();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        config.headers["Content-Type"] = "application/json";
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.access_token}`;
        }
        return config;
      },
      (err) => Promise.reject(err)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (res) => res,
      async (err) => {
        const previousRequest = err?.config;

        if (err?.response?.status === 403) {
          window.location.replace("/access-denied");
          // return window.location.replace("/access-denied");
          // navigate("/access-denied");
        }

        // sent will be used to check if a refresh req was sent after first fail
        if (err?.response?.status === 401 && !previousRequest?.sent) {
          previousRequest.sent = true;
          const newToken = await refresh();
          previousRequest.headers["Authorization"] = `Bearer ${newToken}`;
          return axiosPrivate(previousRequest);
        }
        return Promise.reject(err);
      }
    );

    // Remove interceptor on cleanup
    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);
  return axiosPrivate;
};

export default useAxiosPrivate;
