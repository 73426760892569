import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, FormGroup } from "@mui/material";

import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { enqueueSnackbar } from "notistack";

export default function AddProfile() {
  const axios = useAxiosPrivate();
  const { REACT_APP_BASE_API_URL } = process.env;
  const endpoint = "profile";
  const usersEndpoint = "user/all";
  const idTypesEndpoint = "idtype/all";

  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [idType, setIdType] = React.useState("");
  const [idTypes, setIdTypes] = React.useState([]);
  const [idNumber, setIdNumber] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [dob, setDob] = React.useState("");

  const add = (e) => {
    e.preventDefault();

    axios
      .post(`${REACT_APP_BASE_API_URL}/${endpoint}`, {
        first_name: firstName,
        last_name: lastName,
        address,
        id_type: idType,
        id_number: idNumber,
        phone_number: phoneNumber,
        verified: false,
        pin_status: 1,
        gender,
        date_of_birth: dob,
        profile_owner_username: username,
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Profile added successfully.", {
            variant: "success",
          });
          navigate("/profiles");
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "profile_owner_username":
        setUsername(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "date_of_birth":
        setDob(value);
        break;
      case "phone_number":
        setPhoneNumber(value);
        break;
      case "id_type":
        setIdType(value);
        break;
      case "id_number":
        setIdNumber(value);
        break;
      case "gender":
        setGender(value);
        break;

      default:
        enqueueSnackbar(`Unknowm field ${name}.`, {
          variant: "error",
        });
    }
  };

  React.useEffect(() => {
    setLoading(true);
    let usernames = [];
    let idTypeNames = [];

    axios
      .get(`${REACT_APP_BASE_API_URL}/${idTypesEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((t) => {
          idTypeNames.push(t.type);
        });
      })
      .then((_) => setIdTypes(idTypeNames));

    axios
      .get(`${REACT_APP_BASE_API_URL}/${usersEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((u) => {
          usernames.push(u.username);
        });
      })
      .then((_) => {
        setUsers(usernames);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <Typography
        component={"h1"}
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        Add New Profile
      </Typography>
      {loading ? (
        "LOADING..."
      ) : (
        <Box mt={2} component="form" onSubmit={add}>
          <FormGroup>
            <Grid item direction={"row"}>
              <TextField
                fullWidth
                label="First Name"
                id="firstName"
                name="firstName"
                variant="outlined"
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Lastname"
                id="lastName"
                name="lastName"
                variant="outlined"
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Address"
                id="address"
                name="address"
                variant="outlined"
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>

            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="id_type">ID Type</InputLabel>
                <Select
                  labelId="id_type"
                  id="id_type"
                  label="ID Type"
                  name="id_type"
                  sx={{ mb: 1 }}
                  defaultValue={""}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {idTypes.map((t, index) => (
                    <MenuItem value={t} key={index}>
                      {t}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <TextField
                fullWidth
                label="ID Number"
                id="id_number"
                name="id_number"
                variant="outlined"
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Phone"
                id="phone_number"
                name="phone_number"
                variant="outlined"
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Date of Birth"
                id="date_of_birth"
                name="date_of_birth"
                type="date"
                variant="outlined"
                sx={{ mb: 1 }}
                onChange={handleChange}
                focused
              />
            </Grid>

            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="profile_owner_username">
                  Profile Owner
                </InputLabel>
                <Select
                  labelId="profile_owner_username"
                  id="profile_owner_username"
                  label="Profile Owner"
                  name="profile_owner_username"
                  defaultValue={""}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {users.map((user, index) => (
                    <MenuItem value={user} key={index}>
                      {user}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl required>
                <FormLabel id="gender">Gender</FormLabel>
                <RadioGroup
                  aria-labelledby="gender"
                  name="gender"
                  id="gender"
                  variant="outlined"
                  sx={{ mb: 1 }}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                startIcon={<CheckIcon />}
                type="submit"
                color="primary"
                sx={{ m: 1 }}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                color="warning"
                sx={{ m: 1 }}
                onClick={() => navigate(`${"/profiles"}`)}
              >
                Cancel
              </Button>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </React.Fragment>
  );
}
