import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { Grid, FormGroup } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate } from "react-router-dom";

const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "user";

export default function AddUser() {
  const [user, setUser] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [client, setClient] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [roles, setRoles] = React.useState([]);
  const [permissions, setPermissions] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [notifications, setNotifications] = React.useState([]);
  const [roleName, setRoleName] = React.useState([]);
  const [permissionName, setPermissionName] = React.useState([]);
  const [notificationName, setNotificationName] = React.useState([]);
  const axios = useAxiosPrivate();
  const [assignedRoles, setAssignedRoles] = React.useState("");
  const [assignedPermissions, setAssignedPermissions] = React.useState("");
  const [notificationSubscriptions, setNotificationSubs] = React.useState("");
  const [canAccessUi, setCanAccessUi] = React.useState(true);
  const navigate = useNavigate();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setRoleName(duplicateRemoved);
    setAssignedRoles(_.join(duplicateRemoved, ","));
  };

  const handleNotificationChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setNotificationName(duplicateRemoved);
    setNotificationSubs(_.join(duplicateRemoved, ","));
  };

  const handlePermissionChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setPermissionName(duplicateRemoved);
    setAssignedPermissions(_.join(duplicateRemoved, ","));
  };

  // Get roles, permission and notifications
  const getPrePopulatedFields = () => {
    const rolesUrl = "role/all";
    const permissionsUrl = "module/all";
    const notificationsUrl = "notification/all";
    const clientsUrl = "client/all";

    let roleNames = [];
    let notificationNames = [];
    let clientNames = [];
    let permissionNames = [];

    axios.get(`${REACT_APP_BASE_API_URL}/${rolesUrl}`).then((res) => {
      const { result } = res.data;
      result.map((role) => {
        roleNames.push(role.role_name);
      });
    });

    axios.get(`${REACT_APP_BASE_API_URL}/${permissionsUrl}`).then((res) => {
      const { result } = res.data;
      result.map((app) => {
        app.permissions.map((permission) =>
          permissionNames.push(permission.perm)
        );
      });
    });

    axios.get(`${REACT_APP_BASE_API_URL}/${notificationsUrl}`).then((res) => {
      const { result } = res.data;
      result.map((notification) => {
        notificationNames.push(notification.notification_name);
      });
    });

    axios.get(`${REACT_APP_BASE_API_URL}/${clientsUrl}`).then((res) => {
      const { result } = res.data;
      result.map((c) => {
        clientNames.push(c.client_name);
      });
    });

    setRoles(roleNames);
    setNotifications(notificationNames);
    setClients(clientNames);
    setPermissions(permissionNames);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "client":
        setClient(value);
        break;
      case "username":
        setUsername(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "can_access_ui":
        setCanAccessUi(value);
        break;
      default:
        enqueueSnackbar(`Unknown field : ${name}.`, {
          variant: "error",
        });
    }
  };

  const add = (e) => {
    const data = new FormData(e.currentTarget);
    e.preventDefault();

    const confirmPassword = data.get("confirmPassword");

    if (username && client && email && password) {
      if (password !== confirmPassword)
        return enqueueSnackbar(`Passwords do not match.`, {
          variant: "error",
        });
    } else {
      return enqueueSnackbar(`Please fill all required fields.`, {
        variant: "error",
      });
    }

    let newUser = {};
    newUser.username = username;
    newUser.email = email;
    newUser.password = password;
    newUser.client = client;
    newUser.roles = assignedRoles;
    newUser.permissions = assignedPermissions;
    newUser.notification_subscriptions = notificationSubscriptions;
    newUser.can_access_ui = canAccessUi;
    setUser(newUser);
    console.log(user);
    axios
      .post(`${REACT_APP_BASE_API_URL}/${endpoint}`, user)
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("User added successfully.", {
            variant: "success",
          });
        }

        navigate(`${"/users"}`);
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  React.useEffect(() => {
    getPrePopulatedFields();
  }, []);

  return (
    <React.Fragment>
      <Typography
        component={"h1"}
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        Add New User
      </Typography>
      {loading ? (
        "LOADING..."
      ) : (
        <Box mt={2} component="form" onSubmit={add}>
          <FormGroup>
            <Grid item direction={"row"}>
              <TextField
                fullWidth
                label="Username"
                id="username"
                name="username"
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Email"
                id="email"
                name="email"
                type="email"
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Password"
                id="password"
                name="password"
                type="password"
                sx={{ mb: 1 }}
                onChange={handleChange}
              />
            </Grid>

            <Grid item></Grid>

            <Grid item>
              <TextField
                fullWidth
                label="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                sx={{ mb: 1 }}
                type="password"
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="permissions">Permissions</InputLabel>
                <Select
                  labelId="permissions"
                  id="permissions"
                  name="permissions"
                  sx={{ mb: 1 }}
                  multiple
                  value={permissionName}
                  onChange={handlePermissionChange}
                  input={<OutlinedInput label="Permissions" />}
                  renderValue={(selected) =>
                    _.toString(selected).length > 30
                      ? _.join(selected, ", ").slice(0, 33).concat("...")
                      : selected.map((x) => x).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {permissions.map((permission, index) => (
                    <MenuItem key={index} value={permission}>
                      <Checkbox
                        checked={
                          permissionName.findIndex(
                            (item) => item === permission
                          ) >= 0
                        }
                      />
                      <ListItemText primary={permission} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="notifications">Notifications</InputLabel>
                <Select
                  labelId="notifications"
                  id="notifications"
                  name="notifications"
                  sx={{ mb: 1 }}
                  multiple
                  value={notificationName}
                  onChange={handleNotificationChange}
                  input={<OutlinedInput label="Notifications" />}
                  renderValue={(selected) =>
                    _.toString(selected).length > 30
                      ? _.join(selected, ", ").slice(0, 33).concat("...")
                      : selected.map((x) => x).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {notifications.map((notification, index) => (
                    <MenuItem key={index} value={notification}>
                      <Checkbox
                        checked={
                          notificationName.findIndex(
                            (item) => item === notification
                          ) >= 0
                        }
                      />
                      <ListItemText primary={notification} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="selectClient">Client</InputLabel>
                <Select
                  labelId="client"
                  id="client"
                  value={client}
                  label="Client"
                  name="client"
                  sx={{ mb: 1 }}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {clients.map((client, index) => (
                    <MenuItem value={client} key={index}>
                      {client}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="role">Roles</InputLabel>
                <Select
                  labelId="roles"
                  id="roles"
                  name="roles"
                  multiple
                  sx={{ mb: 1 }}
                  value={roleName}
                  onChange={handleRoleChange}
                  input={<OutlinedInput label="Roles" />}
                  renderValue={(selected) =>
                    _.toString(selected).length > 30
                      ? _.join(selected, ", ").slice(0, 33).concat("...")
                      : selected.map((x) => x).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {roles.map((role, index) => (
                    <MenuItem key={index} value={role}>
                      <Checkbox
                        checked={
                          roleName.findIndex((item) => item === role) >= 0
                        }
                      />
                      <ListItemText primary={role} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl required>
                <FormLabel id="can_access_ui">Can Access UI</FormLabel>
                <RadioGroup
                  aria-labelledby="can_access_ui"
                  name="can_access_ui"
                  id="can_access_ui"
                  variant="outlined"
                  sx={{ mb: 1 }}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                startIcon={<CheckIcon />}
                type="submit"
                color="primary"
                sx={{ m: 1 }}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                color="warning"
                sx={{ m: 1 }}
                onClick={() => navigate(`${"/users"}`)}
              >
                Cancel
              </Button>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </React.Fragment>
  );
}
