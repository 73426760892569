import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ListSubheader } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Grid, Typography } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";

const style = {
  width: "100%",
  bgcolor: "background.paper",
  flexDirection: "colum",
};

const endpoint = "account-transaction";
const { REACT_APP_ACCOUNTS_API_URL } = process.env;

export default function AccountTransaction({}) {
  const { id } = useParams();
  const [transaction, setTransaction] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const axios = useAxiosPrivate();
  React.useEffect(() => {
    setLoading(true);
    axios.get(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`).then((res) => {
      const { result } = res.data;
      setTransaction(result);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        "Loading..."
      ) : (
        <Grid sx={{ display: "flex", flexDirection: "row" }}>
          <List sx={style} component="nav" aria-label="transaction details">
            <ListSubheader>Transaction Details</ListSubheader>
            <Typography>
              <ListItem button>
                <ListItemText primary={"Transaction ID"} />
                <Typography>{transaction.transaction_id}</Typography>
              </ListItem>
              <Divider />
            </Typography>
            <Typography>
              <ListItem button>
                <ListItemText primary={"Currency"} />
                <Typography>{transaction.currency}</Typography>
              </ListItem>
              <Divider />
            </Typography>
            <Typography>
              <ListItem button>
                <ListItemText primary={"Amount"} />
                <Typography>{transaction.amount}</Typography>
              </ListItem>
              <Divider />
            </Typography>
            <Typography>
              <ListItem button>
                <ListItemText primary={"Charge"} />
                <Typography>{transaction.charge}</Typography>
              </ListItem>
              <Divider />
            </Typography>
            <Typography>
              <ListItem button>
                <ListItemText primary={"Starting Balance"} />
                <Typography>{transaction.starting_balance}</Typography>
              </ListItem>
              <Divider />
            </Typography>
            <Typography>
              <ListItem button>
                <ListItemText primary={"Closing Balance"} />
                <Typography>{transaction.closing_balance}</Typography>
              </ListItem>
              <Divider />
            </Typography>
            <Typography>
              <ListItem button>
                <ListItemText primary={"Reversal Reason"} />
                <Typography>{transaction.reversal_reason}</Typography>
              </ListItem>
              <Divider />
            </Typography>
            <Typography>
              <ListItem button>
                <ListItemText primary={"Reversal Status"} />
                <Typography>{transaction.reversal_status}</Typography>
              </ListItem>
              <Divider />
            </Typography>
            <Typography>
              <ListItem button>
                <ListItemText primary={"Checker Narration"} />
                <Typography>{transaction.checker_narration}</Typography>
              </ListItem>
              <Divider />
            </Typography>

            <Typography>
              <ListItem button>
                <ListItemText primary={"Date Created"} />
                <Typography>{transaction.created_at}</Typography>
              </ListItem>
              <Divider />
            </Typography>
          </List>
          <Grid container sx={{ flexDirection: "column" }}></Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}
