import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { FormGroup, Grid, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddAccount() {
  const axios = useAxiosPrivate();

  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [username, setUsername] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [runningBalance, setRunningBalance] = React.useState("");

  const [minimumBalance, setMinimumBalance] = React.useState("");

  const [state, setState] = React.useState("CREATED");

  const [approved, setApproved] = React.useState(true);
  const [previousState, setPrevState] = React.useState("CREATED");
  const [accountType, setAccountType] = React.useState("");
  const [accountTypes, setAccountTypes] = React.useState([]);
  const [billerName, setBillerName] = React.useState([]);
  const [billers, setBillers] = React.useState([]);

  const [authorizedBillers, setAuthBillers] = React.useState("");

  const endpoint = "account";
  const usersEndpoint = "user/all";
  const accountTypeEndpoint = "account-type/all";
  const billerEndpoint = "biller/all";

  const { REACT_APP_ACCOUNTS_API_URL, REACT_APP_BASE_API_URL } = process.env;
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "account_owner_username":
        setUsername(value);
        break;
      case "account_number":
        setAccountNumber(value);
        break;
      case "running_balance":
        setRunningBalance(value);
        break;
      case "minimum_balance":
        setMinimumBalance(value);
        break;
      case "account_type":
        setAccountType(value);
        break;
      case "billers":
        setAuthBillers(value);
        break;

      default:
        enqueueSnackbar(`Unknown field : ${name}`, {
          variant: "success",
        });
    }
  };

  const handleBillerChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setBillerName(duplicateRemoved);
    setAuthBillers(_.join(duplicateRemoved, ","));
  };

  const AddAccount = (e) => {
    e.preventDefault();

    console.log(accountTypes);

    let payload = {
      account_owner_username: username,
      account_number: accountNumber,
      running_balance: runningBalance,
      minimum_balance: minimumBalance,
      state,
      approved,
      previous_state_id: previousState,
      account_type_id: accountType,
      authorized_billers: authorizedBillers,
    };

    axios
      .post(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}`, payload)
      .then((res) => {
        if (res.status === 200) {
          return enqueueSnackbar("Account added successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  // Get usernames, account types
  const getPrePopulatedFields = () => {
    setLoading(true);

    let usernames = [];
    let billerNames = [];
    let accTypes = [];

    axios.get(`${REACT_APP_ACCOUNTS_API_URL}/${billerEndpoint}`).then((res) => {
      const { result } = res.data;
      _.map(result, (biller) => {
        billerNames.push(biller.biller_name);
      });
      setBillers(billerNames);
    });

    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${accountTypeEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        _.map(result, (accType) => {
          const { id, account_type } = accType;
          accTypes.push({
            id,
            account_type,
          });
        });
        setAccountTypes(accTypes);
      });

    axios
      .get(`${REACT_APP_BASE_API_URL}/${usersEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((u) => {
          usernames.push(u.username);
        });
      })
      .then((_) => {
        setUsers(usernames);
      })
      .then((_) => setLoading(false));
  };

  React.useEffect(() => {
    getPrePopulatedFields();
  }, []);

  return (
    <React.Fragment>
      <Typography
        component={"h1"}
        sx={{ textAlign: "center", fontWeight: "bold" }}
      >
        Add New Account
      </Typography>
      {loading ? (
        "LOADING..."
      ) : (
        <Box mt={2} component="form" onSubmit={AddAccount}>
          <FormGroup>
            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="account_owner_username">
                  Owner Username
                </InputLabel>
                <Select
                  labelId="account_owner_username"
                  id="account_owner_username"
                  label="Account Owner Username"
                  name="account_owner_username"
                  defaultValue={""}
                  onChange={handleChange}
                  sx={{ mb: 1 }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {users.map((user, index) => (
                    <MenuItem value={user} key={index}>
                      {user}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl required fullWidth>
                <InputLabel id="account_type">Account Type</InputLabel>
                <Select
                  labelId="account_type"
                  id="account_type"
                  label="Account Type"
                  name="account_type"
                  defaultValue={""}
                  sx={{ mb: 1 }}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {accountTypes.map((type, index) => (
                    <MenuItem value={type.id} key={index}>
                      {type.account_type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl fullWidth>
                <InputLabel id="billers">Billers</InputLabel>
                <Select
                  labelId="billers"
                  id="billers"
                  name="Billers"
                  multiple
                  value={billerName}
                  onChange={handleBillerChange}
                  input={<OutlinedInput label="Billers" />}
                  renderValue={(selected) =>
                    _.toString(selected).length > 30
                      ? _.join(selected, ", ").slice(0, 33).concat("...")
                      : selected.map((x) => x).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {billers.map((biller, index) => (
                    <MenuItem key={index} value={biller}>
                      <Checkbox
                        checked={
                          billerName.findIndex((item) => item === biller) >= 0
                        }
                      />
                      <ListItemText primary={biller} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <TextField
              fullWidth
              required
              margin="dense"
              id="account_number"
              name="account_number"
              label="Account Number"
              variant="outlined"
              sx={{ mb: 1 }}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              margin="dense"
              id="running_balance"
              name="running_balance"
              label="Running Balance"
              variant="outlined"
              type="number"
              sx={{ mb: 1 }}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              required
              margin="dense"
              id="minimum_balance"
              name="minimum_balance"
              label="Minimum Balance"
              variant="outlined"
              type="number"
              sx={{ mb: 1 }}
              onChange={handleChange}
            />

            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                startIcon={<CheckIcon />}
                type="submit"
                color="primary"
                sx={{ m: 1 }}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                startIcon={<CancelIcon />}
                // type="submit"
                color="warning"
                sx={{ m: 1 }}
                onClick={() => navigate(`${"/accounts"}`)}
              >
                Cancel
              </Button>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </React.Fragment>
  );
}
