import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import { AppContext } from "../helpers/contexts/AppContext";
import { someFiledsEmpty } from "../helpers/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const { REACT_APP_ACCOUNTS_API_URL } = process.env;
const endpoint = "cashout-option";

export default function AddAccountType() {
  const [open, setOpen] = React.useState(false);
  const [optionName, setOptionName] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [collectionAccNum, setCollectionAccNum] = React.useState("");
  const axios = useAxiosPrivate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { showAlert, alertData, setAlertData, setShowAlert, MainAlert } =
    React.useContext(AppContext);

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "option_name":
        setOptionName(value);
        break;
      case "url":
        setUrl(value);
        break;
      case "collection_account_number":
        setCollectionAccNum(value);
        break;

      default:
        enqueueSnackbar(`Unknown field : ${name}`, {
          variant: "error",
        });
    }
  };

  const add = (e) => {
    e.preventDefault();

    if (someFiledsEmpty([optionName, url, collectionAccNum])) {
      enqueueSnackbar(`Please complete fields.`, {
        variant: "error",
      });
    }

    setOpen(false);
    axios
      .post(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}`, {
        option_name: optionName,
        url,
        collection_account_number: collectionAccNum,
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar(`Cashout option added successfully.`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  return (
    <React.Fragment>
      {showAlert && <MainAlert alert={alertData} />}

      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        pb={1}
      >
        <Paper>
          <div>
            <Button onClick={handleOpen}>Add New cashout Option</Button>
            <Modal
              aria-labelledby="addCashoutOption"
              aria-describedby="add new cashout option"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="addCashoutOption"
                    variant="h6"
                    component="h2"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Enter Account Type Details
                  </Typography>

                  <Box
                    mt={2}
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                      m: 1,
                    }}
                    component="form"
                    onSubmit={add}
                  >
                    <Grid container direction={"column"} spacing={2}>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Option Name"
                          id="option_name"
                          name="option_name"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Collection Acc Number"
                          id="collection_account_number"
                          name="collection_account_number"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="URL"
                          id="url"
                          name="url"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          type="submit"
                          color="primary"
                        >
                          Add New Cashout Option
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Fade>
            </Modal>
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
