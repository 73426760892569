import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import _ from "lodash";
import * as React from "react";
import ConfirmDialog from "../container/ConfirmDialog/ConfirmDialog";
import Edit from "../container/Edit";
import { AppContext } from "../helpers/contexts/AppContext";
import AddAccountState from "./AddAccountState";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

export default function AccountStates() {
  const endpoint = "account-state";
  const { REACT_APP_ACCOUNTS_API_URL } = process.env;
  const [accountStates, setAccountStates] = React.useState([]);
  const axios = useAxiosPrivate();
  const {
    openConfirmDialog,
    handleOpenConfirmDialog,
    handleCloseConfirmDialog,
    handleCloseEditDialog,
    handleOpenEditDialog,
    openEditDialog,
    edit,
    auth,
  } = React.useContext(AppContext);

  const skipStart = 0;
  const skipEnd = -1;
  const displayStart = 0;
  const displayEnd = -1;
  const nonUpdatableFields = ["id", "transaction_types"];

  const [confirmMessage, setConfirmMessage] = React.useState("");
  const [dialogHeader, setDialogHeader] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [actionPresent, setactionPresent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [payload, setPayload] = React.useState(null);

  const [editDialogHeader, setEditDialogHeader] = React.useState("");
  const [editActionPresent, setEditActionPresent] = React.useState(false);
  const [param, setParam] = React.useState(null);
  const [editRow, setEditRow] = React.useState({});

  const deleteAccount = (id) => {
    axios
      .delete(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`)
      .then((res) => {
        if (res.status && res.status === 200) {
          setAccountStates(accountStates.filter((s) => s.id !== id));

          return enqueueSnackbar("Account state deleted successfully.", {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
          variant: "error",
        });
      });
  };

  const handleEdit = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setEditRow(thisRow);
    setEditDialogHeader("Update Roles Details");
    setEditActionPresent(true);
    setAction("edit");
    setParam(thisRow.id);
    handleOpenEditDialog();
  };

  const handleDelete = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
    setConfirmMessage(
      `Are you sure you want to delete account state ${thisRow.state}?`
    );
    setDialogHeader("Delete Account State");
    setactionPresent(true);
    setAction("delete");
    setPayload(thisRow.id);
    handleOpenConfirmDialog();
  };

  const actions = [
    {
      name: "edit",
      onclick: (e, params) => handleEdit(e, params),
      icon: <EditIcon />,
      color: "primary",
      role: "EDIT_ACCOUNT_STATE",
    },
    {
      name: "delete",
      onclick: (e, params) => handleDelete(e, params),
      icon: <DeleteIcon />,
      color: "warning",
      role: "DELETE_ACCOUNT_STATE",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "state", headerName: "State", width: 100 },
    { field: "description", headerName: "Description", width: 150 },
    { field: "transaction_types", headerName: "Transaction Types", width: 300 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Tooltip title={action.name}>
              <Button
                variant="outlined"
                startIcon={action.icon}
                sx={{ m: 1 }}
                onClick={(e) => action.onclick(e, params)}
                color={action.color}
                aria-label={action.label}
                key={action.name}
              >
                {action.label}
              </Button>
            </Tooltip>
          ) : (
            ""
          )
        );
      },
      width: 250,
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    let states = [];
    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/all`)
      .then((res) => {
        const { result } = res.data;
        _.map(result, (row) => {
          row.transaction_types = _.join(
            _.map(row.allowed_transaction_types, (t) => t.type),
            ","
          );

          states.push(row);
        });
        setAccountStates(states);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      {openEditDialog && (
        <Edit
          openEditDialog={openEditDialog}
          handleOpenEditDialog={handleOpenEditDialog}
          handleCloseEditDialog={handleCloseEditDialog}
          dialogHeader={editDialogHeader}
          actionPresent={editActionPresent}
          action={action}
          handler={edit}
          param={param}
          row={editRow}
          skipStart={skipStart}
          skipEnd={skipEnd}
          displayStart={displayStart}
          displayEnd={displayEnd}
          url={REACT_APP_ACCOUNTS_API_URL}
          endpoint={endpoint}
          collection={accountStates}
          entity="Account State"
          setter={setAccountStates}
          nonUpdatableFields={nonUpdatableFields}
        />
      )}

      {openConfirmDialog && (
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleOpenConfirmDialog={() => handleOpenConfirmDialog()}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
          dialogHeader={dialogHeader}
          message={confirmMessage}
          actionPresent={actionPresent}
          action={action}
          handler={deleteAccount}
          payload={payload}
        />
      )}
      <AddAccountState />
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={accountStates}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
}
