import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const ConfirmDialog = ({
  openConfirmDialog,
  handleCloseConfirmDialog,
  dialogHeader,
  message,
  actionPresent,
  action,
  payload,
  handler,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{dialogHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            autoFocus
            onClick={() => handleCloseConfirmDialog(false, handler, payload)}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleCloseConfirmDialog(actionPresent, handler, payload)
            }
            autoFocus
            variant="contained"
            color="warning"
          >
            {(action && action) || "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
