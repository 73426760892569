import _ from "lodash";
export const someFiledsEmpty = (arr) => {
  _.map(arr, (value) => {
    if (_.isEmpty(value)) return true;
  });
};

export const getSessionData = () => {
  return JSON.parse(sessionStorage.getItem("sd"));
};

export const setSessionData = (d) => {
  sessionStorage.setItem("sd", JSON.stringify(d));
};
