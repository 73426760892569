import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Button, Grid } from "@mui/material";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { AppContext } from "../helpers/contexts/AppContext";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CSVLink } from "react-csv";

export default function BillerTransactions() {
  const endpoint = "biller-service-transaction/by-account";
  const limit = 100;
  const { REACT_APP_ACCOUNTS_API_URL } = process.env;
  const { accountNumber } = useParams();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [transactions, setTransactions] = React.useState([]);
  const { CustomToolbar, auth } = React.useContext(AppContext);

  const [viewTrans, setViewTrans] = React.useState({});
  const [shouldRedirect, setShouldRedirect] = React.useState(false);

  const handleView = (e, params) => {
    e.stopPropagation(); // don't select this row after clicking

    const api = params.api;
    const thisRow = {};

    api
      .getAllColumns()
      .filter((c) => c.field !== "__check__" && !!c)
      .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

    setViewTrans(thisRow);
    setShouldRedirect(true);
  };

  const reverseTransaction = (id) => {
    return alert(id);
  };

  const actions = [
    {
      name: "view",
      onclick: (e, params) => handleView(e, params),
      icon: <VisibilityIcon />,
      color: "primary",
      label: "View",
      role: "VIEW_BILLER_TRANSACTION",
    },
    {
      name: "reverse",
      onclick: (e, params) => reverseTransaction(e, params),
      icon: <SettingsBackupRestoreIcon />,
      color: "warning",
      label: "Reverse",
      role: "REVERSE_BILLER_TRANSACTION",
    },
  ];

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      width: 150,
    },
    { field: "customer_account", headerName: "Cust. Acc", width: 100 },
    { field: "biller_account", headerName: "Biller. Acc", width: 100 },
    { field: "currency", headerName: "Currency", width: 100 },
    { field: "amount", headerName: "Amount", width: 100 },
    { field: "service_charge", headerName: "Charge", width: 100 },
    { field: "reversed", headerName: "Reversed", width: 100 },
    { field: "created_at", headerName: "Date Created", width: 150 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return actions.map((action) =>
          auth?.permissions?.find(
            (perm) => action.role.includes(perm) || perm === "ROOT"
          ) ? (
            <Button
              variant="outlined"
              startIcon={action.icon}
              sx={{ m: 1 }}
              onClick={(e) => action.onclick(e, params)}
              color={action.color}
              aria-label={action.label}
            >
              {action.label}
            </Button>
          ) : (
            ""
          )
        );
      },
      width: 250,
    },
  ];

  React.useEffect(() => {
    shouldRedirect &&
      navigate(`/transfer-transactions/transaction/${viewTrans.id}`);
    setLoading(true);

    let transList = [];
    axios
      .get(
        `${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${accountNumber}?limit=${limit}`
      )
      .then((res) => {
        const { result } = res.data;
        result.map((transaction) => {
          transList.push(transaction);
        });
        setTransactions(transList);
        setLoading(false);
      });
  }, [shouldRedirect]);

  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          rows={transactions}
          columns={columns}
          rowsPerPageOptions={[20, 50, 100]}
          getRowHeight={() => "auto"}
          disableColumnMenu
          loading={loading}
          components={{ Toolbar: CustomToolbar }}
        />
        <Grid sx={{ mt: 2, justifyContent: "center" }}>
          <Button
            variant="outlined"
            startIcon={<UploadFileIcon />}
            sx={{ m: 1 }}
            aria-label="export-transactions"
          >
            <Typography>
              <CSVLink
                data={transactions}
                style={{ textDecoration: "none" }}
                filename={`${accountNumber}-transactions_${new Date()}`}
              >
                Export
              </CSVLink>
            </Typography>
          </Button>
        </Grid>
      </div>
    </React.Fragment>
  );
}
