import axios from "../api/axios";
import { useContext } from "react";
import { AppContext } from "../helpers/contexts/AppContext";
import { getSessionData } from "../helpers/utils";

const endpoint = "pucss/auth/login/refresh";
const sd = getSessionData();

const useRefreshToken = () => {
  const { setAuth } = useContext(AppContext);
  const refresh = async () => {
    const res = await axios.post(
      `${endpoint}`,
      {},
      {
        headers: { Authorization: `Bearer ${sd?.rt ? sd.rt : null}` },

        withCredentials: true,
      }
    );

    const { access_token } = res.data;
    sd &&
      setAuth({
        permissions: sd.p,
        users: sd.u,
        client: sd.c,
        access_token,
      });
    return access_token;
  };

  return refresh;
};

export default useRefreshToken;
