import { Button, Chip, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../helpers/contexts/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const style = {
  width: "100%",
  display: "flex",
  bgcolor: "background.paper",
  flexDirection: "column",
};

const { REACT_APP_BASE_API_URL } = process.env;
const endpoint = "client";
const redirectUrl = "/clients";

export default function Client() {
  const [client, setClient] = useState({});
  const { deleteAndRedirect } = useContext(AppContext);
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { id } = useParams();

  useEffect(() => {
    axios.get(`${REACT_APP_BASE_API_URL}/${endpoint}/${id}`).then((res) => {
      const { result } = res.data;
      setClient(result);
    });
  }, []);

  return (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <List sx={style} component="nav" aria-label="Client Details">
        <ListItem button>
          <ListItemText primary="First Name" />
          <Typography>{client.clientName}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Address" />
          <Typography>{client.physicalAddress}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Email" />
          <Typography>{client.primaryEmail}</Typography>
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemText primary="Phone" />
          <Typography>{client.primaryTel}</Typography>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Users" mr={1} />
          {client.clientUsers && client.clientUsers.length > 5 ? (
            <ListItem>
              <Divider orientation="vertical" />
              <Typography
                sx={
                  client.clientUsers && client.clientUsers.length === 0
                    ? { fontStyle: "italic", fontWeight: 100 }
                    : {}
                }
              >
                {client.clientUsers && client.clientUsers.length > 0
                  ? client.clientUsers.map((user) => (
                      <Link
                        to={`/users/${user.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Chip
                          label={user.username}
                          // variant="outlined"
                          sx={{ m: 1, cursor: "grab" }}
                          color="primary"
                        />
                      </Link>
                    ))
                  : `No users added.`}
              </Typography>
            </ListItem>
          ) : (
            <Typography
              sx={
                client.clientUsers && client.clientUsers.length === 0
                  ? { fontStyle: "italic", fontWeight: 100 }
                  : {}
              }
            >
              {client.clientUsers && client.clientUsers.length > 0
                ? client.clientUsers.map((user) => (
                    <Chip
                      label={user.username}
                      variant="outlined"
                      sx={{ m: 1 }}
                      color="primary"
                    />
                  ))
                : `No users added.`}
            </Typography>
          )}
        </ListItem>
        <Divider />

        <ListItem button>
          <ListItemText primary="Whitelisted IPs" mr={1} />
          {client.whiteListedIps && client.whiteListedIps.length > 5 ? (
            <ListItem>
              <Typography
                sx={
                  client.whiteListedIps && client.whiteListedIps.length === 0
                    ? { fontStyle: "italic", fontWeight: 100 }
                    : {}
                }
              >
                {client.whiteListedIps && client.whiteListedIps.length > 0
                  ? client.whiteListedIps.map((ip) => (
                      <Chip
                        label={ip}
                        variant="outlined"
                        sx={{ m: 1 }}
                        color="primary"
                      />
                    ))
                  : `No IPs added.`}
              </Typography>
            </ListItem>
          ) : (
            <Typography
              sx={
                client.whiteListedIps && client.whiteListedIps.length === 0
                  ? { fontStyle: "italic", fontWeight: 100 }
                  : {}
              }
            >
              {client.whiteListedIps && client.whiteListedIps.length > 0
                ? client.whiteListedIps.map((ip) => (
                    <Chip
                      label={ip}
                      variant="outlined"
                      sx={{ m: 1 }}
                      color="primary"
                    />
                  ))
                : `No IPs added.`}
            </Typography>
          )}
        </ListItem>
        <Divider />
      </List>

      <Grid sx={{ mt: 2, justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="export-transactions"
          onClick={() => navigate(redirectUrl)}
        >
          View All Clients
        </Button>
        <Button
          variant="outlined"
          sx={{ m: 0.5 }}
          aria-label="export-transactions"
          color="warning"
          onClick={() =>
            deleteAndRedirect(
              id,
              `${REACT_APP_BASE_API_URL}/${endpoint}`,
              "Client",
              true,
              navigate,
              redirectUrl
            )
          }
        >
          Delete This Client
        </Button>
      </Grid>
    </Grid>
  );
}
