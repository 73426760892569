import { Grid, ListSubheader, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import * as React from "react";
import { useParams } from "react-router-dom";

const style = {
  width: "100%",
  //   maxWidth: 360,
  bgcolor: "background.paper",
  flexDirection: "colum",
};

const endpoint = "biller-transaction";
const { REACT_APP_ACCOUNTS_API_URL } = process.env;

export default function BillerTransaction({}) {
  const { id } = useParams();
  const [transaction, setTransaction] = React.useState({});
  const axios = useAxiosPrivate();
  React.useEffect(() => {
    axios.get(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}/${id}`).then((res) => {
      const { result } = res.data;
      setTransaction(result);
    });
  }, []);

  return (
    <Grid sx={{ display: "flex", flexDirection: "row" }}>
      <List sx={style} component="nav" aria-label="transaction details">
        <ListSubheader>Transaction Details</ListSubheader>

        {transaction &&
          Object.keys(transaction).map((key) => (
            <Typography>
              <ListItem button>
                <ListItemText
                  primary={
                    key.includes("_")
                      ? key
                          .split("_")
                          .filter((x) => x.length > 0)
                          .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
                          .join(" ")
                      : key[0].toUpperCase() + key.substring(1)
                  }
                />
                <Typography>{transaction[key]}</Typography>
              </ListItem>
              <Divider />
            </Typography>
          ))}
      </List>
      <Grid container sx={{ flexDirection: "column" }}></Grid>
    </Grid>
  );
}
