import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FormGroup, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const steps = ["Create Module", "Create permissions", "Preview"];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [module, setModule] = React.useState({});
  const [permissions, setPermissions] = React.useState([]);
  const axios = useAxiosPrivate();
  const mod = {};
  const perms = [];

  const endpoint = "module";
  const { REACT_APP_BASE_API_URL } = process.env;

  const add = () => {
    if (!module || !permissions) {
      enqueueSnackbar("Please fill all fields.", {
        variant: "error",
      });
    } else {
      axios
        .post(`${REACT_APP_BASE_API_URL}/${endpoint}`, {
          module_name: module.name,
          module_desc: module.description,
          permissions,
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            enqueueSnackbar("Module added successfully.", {
              variant: "success",
            });
          }
        })
        .catch((err) => {
          return enqueueSnackbar(`${err.response.data.apiErrors[0].cause}`, {
            variant: "error",
          });
        });
    }
  };

  const handleAppChange = (event) => {
    const { name, value } = event.target;
    mod[name] = value;
  };

  const handlePermSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const perm = data.get("perm");
    const description = data.get("description");
    const action = data.get("action");
    const route = data.get("route");

    // TODO: Clear fields after adding a perm

    perms.push({ perm, description, action,route});
  };

  const setters = [
    {
      setter: setModule,
      data: mod,
    },
    { setter: setPermissions, data: perms },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setters[activeStep] && setters[activeStep].setter(setters[activeStep].data);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {activeStep === 0 && (
              <FormGroup>
                <Box>
                  <Typography
                    id="addModule"
                    variant="h6"
                    component="h4"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Enter Module Details
                  </Typography>

                  <Box
                    mt={2}
                    sx={{
                      m: 1,
                    }}
                    component="form"
                    // onSubmit={add}
                  >
                    <Grid container direction={"column"} spacing={2}>
                      <Grid item>
                        <TextField
                          fullWidth
                          label="name"
                          id="name"
                          name="name"
                          onChange={handleAppChange}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth
                          label="description"
                          id="description"
                          name="description"
                          onChange={handleAppChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </FormGroup>
            )}

            {activeStep === 1 && (
              <Box>
                <Typography
                  id="addPermission"
                  variant="h6"
                  component="h4"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Enter Permission Details
                </Typography>

                <Box
                  mt={2}
                  sx={{
                    m: 1,
                  }}
                  component="form"
                  onSubmit={handlePermSubmit}
                >
                  <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                      <TextField
                        reuired
                        fullWidth
                        label="Permission"
                        id="perm"
                        name="perm"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        reuired
                        fullWidth
                        label="Action"
                        id="action"
                        name="action"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        reuired
                        fullWidth
                        label="route"
                        id="route"
                        name="route"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        label="Description"
                        id="description"
                        name="description"
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        type="submit"
                        color="primary"
                      >
                        Add Permission
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}

            {activeStep === 2 && (
              <Grid>
                <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                  {module && (
                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        <Typography variant="h5" component="div">
                          {module.name}
                        </Typography>
                        <Typography variant="body2">
                          {mod.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                <Grid item>
                  <Box sx={{ width: "100%", m: 1, p: 1 }}>
                    <Stack spacing={2}>
                      {permissions &&
                        permissions.map((perm) => (
                          <Item elevation={1}>
                            <Typography>{perm.perm}</Typography>
                            <Typography>{perm.action}</Typography>
                            <Typography>{perm.route}</Typography>
                            <Typography>{perm.description}</Typography>
                          </Item>
                        ))}
                    </Stack>
                  </Box>
                </Grid>

                <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    type="submit"
                    color="primary"
                    onClick={() => add()}
                  >
                    Save Module
                  </Button>
                </Grid>
              </Grid>
            )}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>

            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
