import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const { REACT_APP_ACCOUNTS_API_URL, REACT_APP_BASE_API_URL } = process.env;
const endpoint = "biller";
const servicesEndpoint = "transaction-type/all";
const clientsEndpoint = "client/all";

export default function AddBiller() {
  const [biller, setBiller] = React.useState({});
  const [active, setActive] = React.useState(true);
  const [billerName, setBillerName] = React.useState("");
  const [services, setServices] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [client, setClient] = React.useState("");
  const [serviceNames, setServiceNames] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [clients, setClients] = React.useState([]);

  const axios = useAxiosPrivate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "biller_name":
        setBillerName(value);
        break;
      case "services":
        setServices(value);
        break;
      case "client":
        setClient(value);
        break;
      default:
        alert(`Unknown field : ${event.target.name}`);
    }
  };

  const handleServiceChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o === item) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x === item);
      } else {
        duplicateRemoved.push(item);
      }
    });
    setServiceNames(duplicateRemoved);
  };

  const add = (e) => {
    const data = new FormData(e.currentTarget);
    e.preventDefault();

    const biller_name = data.get("biller_name");
    const services = data.get("services");
    const client_name = data.get("client");
    if (!billerName || !services || !client_name) {
      enqueueSnackbar("Please fill all fields", {
        variant: "error",
      });
    }

    setBiller({ active, biller_name, services, client_name });

    handleClose();
    axios
      .post(`${REACT_APP_ACCOUNTS_API_URL}/${endpoint}`, {
        active,
        biller_name,
        services,
        client_name,
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          enqueueSnackbar("Biller added successfully.", {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Error adding biller.", {
            variant: "success",
          });
        }
      });
  };

  React.useEffect(() => {
    let serviceNamesList = [];
    let clientNames = [];

    setLoading(true);

    axios.get(`${REACT_APP_BASE_API_URL}/${clientsEndpoint}`).then((res) => {
      const { result } = res.data;
      result.map((c) => {
        clientNames.push(c.client_name);
      });
    });

    axios
      .get(`${REACT_APP_ACCOUNTS_API_URL}/${servicesEndpoint}`)
      .then((res) => {
        const { result } = res.data;
        result.map((service) => {
          service.is_biller_service && serviceNamesList.push(service.type);
        });
      })
      .then((_) => {
        setServices(serviceNamesList);
        setClients(clientNames);
      })
      .then((_) => setLoading(false));
  }, []);

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        pb={1}
      >
        <Paper>
          <div>
            <Button onClick={handleOpen}>Add New Biller</Button>
            <Modal
              aria-labelledby="addBiller"
              aria-describedby="Add new biller"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <Box sx={style}>
                  <Typography
                    id="addBiller"
                    variant="h6"
                    component="h2"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Enter Biller Details
                  </Typography>

                  <Box
                    mt={2}
                    sx={{
                      width: 500,
                      maxWidth: "100%",
                      m: 1,
                    }}
                    component="form"
                    onSubmit={add}
                  >
                    <Grid container direction={"column"} spacing={2}>
                      <Grid item>
                        <FormControl required fullWidth>
                          <InputLabel id="selectClient">client</InputLabel>
                          <Select
                            labelId="client"
                            id="client"
                            value={client}
                            label="Client"
                            name="client"
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>

                            {clients.map((client, index) => (
                              <MenuItem value={client} key={index}>
                                {client}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <FormControl fullWidth sx={{ mb: 1 }}>
                          <InputLabel id="services">Services</InputLabel>
                          <Select
                            labelId="services"
                            id="services"
                            name="services"
                            multiple
                            value={serviceNames}
                            onChange={handleServiceChange}
                            input={<OutlinedInput label="Services" />}
                            renderValue={(selected) =>
                              _.toString(selected).length > 30
                                ? _.join(selected, ", ")
                                    .slice(0, 33)
                                    .concat("...")
                                : selected.map((x) => x).join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {services.map((service, index) => (
                              <MenuItem key={index} value={service}>
                                <Checkbox
                                  checked={
                                    serviceNames.findIndex(
                                      (item) => item === service
                                    ) >= 0
                                  }
                                />
                                <ListItemText primary={service} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <TextField
                          required
                          fullWidth
                          label="Biller Name"
                          id="biller_name"
                          name="biller_name"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid
                        item
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          type="submit"
                          color="primary"
                        >
                          Add Biller
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Fade>
            </Modal>
          </div>
        </Paper>
      </Grid>
    </React.Fragment>
  );
}
