import React from "react";
import { ReactComponent as Logo } from "./svgs/add-trans-type.svg";
import { SvgIcon } from "@mui/material";
const AddTransactionType = () => (
  <SvgIcon>
    <Logo />
  </SvgIcon>
);

export default AddTransactionType;
